import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import { useCart, useProducts } from "medusa-react";
import React from "react";

import { Pagination, ViewedProducts } from "@/components/Pagination";
import { ProductCard } from "@/components/Product/ProductCard";
import ProductCardSkeleton from "@/components/Skeleton/Product/Card";
import { usePagination } from "@/lib/hooks/use-pagination";
import { ProductPreviewType } from "@/types/global";

export const Products = ({ searchValue, onClick, sort }: { searchValue: string; onClick: () => void; sort: string }) => {
  const { getPageNumber, getOffset, getLimit } = usePagination(21);
  const { cart } = useCart();
  const { products, isLoading, limit, count } = useProducts(
    {
      q: searchValue,
      region_id: cart?.region_id,
      order: sort.length ? sort : "title",
      expand: "variants,variants.prices",
      limit: getLimit(),
      offset: getOffset(),
    },
    { enabled: !!cart?.region_id },
  );

  return (
    <div className="grid grid-cols-12 gap-5 w-full mb-20">
      <ShowProducts isLoading={isLoading} products={products} onClick={onClick} />

      <div className="col-span-12">
        <div className="flex justify-between mt-14">
          <div>
            <ViewedProducts limit={getLimit()} pageNumber={getPageNumber()} totals={count ?? 0} />
          </div>
          <Pagination limit={limit} totals={count} />
        </div>
      </div>
    </div>
  );
};

const ShowProducts = ({ products, isLoading, onClick }: { products?: PricedProduct[]; isLoading: boolean; onClick: () => void }) => {
  if (isLoading) {
    return <Loading />;
  }

  if (!products?.length) {
    return (
      <div className="col-span-12">
        <p>Keine Produkte gefunden</p>
      </div>
    );
  }

  return (
    <>
      {products?.map((product, index) => (
        <div className="col-span-6 lg:col-span-4 border rounded-3xl" key={index}>
          <ProductCard className="" onClick={onClick} product={product as ProductPreviewType} />
        </div>
      ))}
    </>
  );
};

const Loading = () => {
  const items = [];

  for (let i = 0; i < 3; i++) {
    items.push(<ProductCardSkeleton />);
  }

  return <>{items}</>;
};
