import { InformationCardInterface } from "@/components/(company)/Information/Card";
import { CardItemInterface } from "@/components/(company)/Information/Card/CenteredCard";
import { StatisticWrapperInterface } from "@/components/(company)/Information/StatisticWrapper";
import { CompanySlide } from "@/components/(company)/Slider/CompanySlider";
import { CustomerItemInterface } from "@/components/Customer/List";
import { registry } from "@/components/Registry";
import { fetchStrapi } from "@/data/strapi-fetch";

export interface StrapiPageResponse {
  data: {
    id: number;
    attributes: {
      createdAt: string;
      updatedAt: string;
      publishedAt: string;
      title: string;
      file?: {
        data: StrapiImage;
      };
      medusa_key?: string;
      menu: {
        data: {
          id: number;
          attribute: {
            title: string;
            slug: string;
            createdAt: string;
            updatedAt: string;
          };
        };
      };
      seo: {
        id: number;
        metaTitle: string;
        metaDescription: string;
        keywords: unknown;
        metaRobots: unknown;
        structuredData: unknown;
        canonicalURL: unknown;
        metaSocial: Array<unknown>;
        metaImage: {
          data: unknown;
        };
      };
      staticzone: Array<StrapiDynamicComponent>;
      contentType: string;
    };
  };
}

export interface StrapiDynamicComponent {
  id: number;
  __component: keyof typeof registry;
  padding: boolean | null;
  image?: {
    data: StrapiImage;
  };
  images: ImageSlide[];
  slides: CompanySlide[];
  title: string;
  isRightAlign: boolean;
  subTitle: string;
  customerItem: CustomerItemInterface[];
  items: StatisticWrapperInterface[] | CardItemInterface[];
  rows: InformationCardInterface[];
  text: string;
  imageTitle?: string;
  mediaTitle?: string;
  bannerTitle?: string;
  startingAt?: string;
  endingAt?: string;
}

export interface StrapiMenuResponse extends StrapiResponseMeta {
  data: Array<{
    id: number;
    attributes: {
      title: string;
      slug: string;
      createdAt: string;
      updatedAt: string;
      items: {
        data: Array<StrapiMenuItem>;
      };
    };
  }>;
}

interface ImageSlide {
  image: {
    data: StrapiImage;
  };
}

export interface StrapiMenuItem {
  id: number;
  attributes: {
    order: unknown;
    title: string;
    url: string | null;
    target: string | null;
    createdAt: string;
    updatedAt: string;
    children: {
      data: Array<StrapiMenuItem>;
    };
  };
}

export enum PageType {
  All = "all",
  Home = "home",
  Product = "product",
  Category = "category",
  Cart = "cart",
  Checkout = "checkout",
  Account = "account",
  Search = "search",
  Brand = "brand",
  Campaign = "campaign",
}
export enum HeaderNoticePosition {
  Before = "before",
  After = "after",
}

export interface StrapiBrandResponse extends StrapiResponseMeta {
  data: Array<StrapiBrand>;
}

interface StrapiResponseMeta {
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}

export interface StrapiHeaderNotice {
  id: string;
  attributes: {
    message: string;
    position: HeaderNoticePosition;
    pageType: PageType;
    icon?: {
      data: {
        attributes: {
          url: string;
          mime?: string;
        };
      };
    };
    textColor?: string;
    backgroundColor?: string;
    startingAt?: string;
    endingAt?: string;
  };
}

export interface StrapiHeaderNoticeResponse extends StrapiResponseMeta {
  data: Array<StrapiHeaderNotice>;
}

export interface StrapiShowResponse extends StrapiResponseMeta {
  data: Array<StrapiShow>;
}

export interface StrapiBrandFilter {
  id: number;
  brandfilter: string | null;
  productstory: string | null;
  skus: string | null;
  categoryfilter: string | null;
}

export interface StrapiBrand {
  id: number;
  attributes: {
    displayname: string;
    link: string;
    headline: string;
    subbrandtitle: string;
    subbrandsubtitle: string;
    subbrandtext: string;
    logobackground: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    productlisting: boolean;
    brandpage: Array<StrapiDynamicComponent>;
    brandparent?: { data: StrapiBrand | null } | null;
    headimage: { data: StrapiImage } | null;
    logo: { data: StrapiImage } | null;
    seo: SeoInterface;
    subbrands: { data: Array<StrapiBrand> };
    subBrandSliderImage: { data: StrapiImage | null };
    customfilter: StrapiBrandFilter[] | null;
  };
}

export interface StrapiShow {
  id: number;
  attributes: {
    showId: string;
    title: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    top: StrapiDynamicComponent[];
    bottom: StrapiDynamicComponent[];
    seo: SeoInterface;
  };
}

export interface StrapiImage {
  id: number;
  attributes: {
    url: string;
    mime: string;
    name: string;
  };
}

interface SeoInterface {
  id: number;
  metaTitle: string;
  metaDescription: string;
  keywords: unknown;
  metaRobots: unknown;
  structuredData: unknown;
  metaViewport: unknown;
  canonicalURL: unknown;
  metaSocial: Array<unknown>;
  metaImage: Array<unknown>;
}

export type StrapiData<T> = {
  id: number;
  attributes: T;
};
interface StrapiBaseObject {
  created_at: string;
  updated_at: string;
}

export interface StrapiRedirectInterface extends StrapiBaseObject {
  url_path: string;
  sku: null | string;
  target_url: null | string;
  product?: StrapiPopulate<{ medusa_id: string; handle: string }>;
  permanent: null | boolean;
}

export type StrapiPopulate<T> = {
  data: null | StrapiData<T>;
};

export type StrapiResponse<T> = {
  data: Array<StrapiData<T>>;
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
};

export const strapiFilter = (slug?: string, webtools = false, category = false) => {
  if (slug && !webtools && !category) {
    return { link: slug };
  }
  if (slug && category) {
    return { slug };
  }

  return {};
};

export async function getStrapiCategoryTopBottom(slug: string) {
  const path = "/categorypages";
  return fetchStrapi(path, strapiFilter(slug, false, true), false);
}
