import React from "react";

import { IconInterface } from "@/components/Icon/IconInterface";

const commonStrokeStyle: React.CSSProperties = {
  stroke: "currentColor",
  strokeWidth: 5,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
};

const st0: React.CSSProperties = {
  ...commonStrokeStyle,
  fill: "none",
};

const st1: React.CSSProperties = {
  ...commonStrokeStyle,
  fill: "#FFFFFF",
};

const st2: React.CSSProperties = {
  ...commonStrokeStyle,
  fill: "currentColor",
  strokeWidth: 3.6925,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
};

const st3: React.CSSProperties = {
  ...commonStrokeStyle,
  fill: "currentColor",
  strokeWidth: "0px",
};

export const FilialFinderIcon = ({ className, width, height, fill }: IconInterface) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113.39 113.39" className={className} width={width} height={height} fill={fill}>
    <polyline style={st0} points="61.76,87.16 22.23,87.16 22.23,40.86 56.11,18.83 90.84,40.86 90.84,47.92 " />
    <path
      style={st1}
      d="M81,54.22c-8.24,0-14.92,6.68-14.92,14.92c0,3.77,1.43,7.36,3.33,10.29C72.65,84.45,81,97.19,81,97.19
      s8.34-12.74,11.59-17.75c1.9-2.93,3.33-6.52,3.33-10.29C95.92,60.9,89.24,54.22,81,54.22z"
    />
    <circle style={st2} cx="81" cy="69.15" r="4.47" />
    <path
      style={st3}
      d="M35.27,76.52c0.27,0.27,0.63,0.41,0.98,0.41c0.35,0,0.71-0.14,0.98-0.41l19.43-19.43
      c0.54-0.54,0.54-1.42,0-1.96s-1.42-0.54-1.96,0L35.27,74.56C34.72,75.1,34.72,75.98,35.27,76.52z"
    />
    <path
      style={st3}
      d="M36.25,67.78c0.35,0,0.71-0.14,0.98-0.41l10.28-10.28c0.54-0.54,0.54-1.42,0-1.96c-0.54-0.54-1.42-0.54-1.96,0
      L35.27,65.41c-0.54,0.54-0.54,1.42,0,1.96C35.54,67.64,35.89,67.78,36.25,67.78z"
    />
  </svg>
);
