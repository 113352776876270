"use client";

import Link from "next/link";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { ArrowDropRightIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropRightIcon";
import { ArrowDropUpIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropUpIcon";
import { isParentMenuLink, RootMenu } from "@/data/Menu/fetch-strapi-menu";

export const FooterItemCollapse = ({ menu }: { menu: RootMenu }) => {
  const [isShow, setShow] = useState<boolean>(false);
  const [showArrows, setShowArrows] = useState<boolean>(false);

  useEffect(() => {
    const matchMedia = window.matchMedia("(min-width: 640px)")?.matches;

    setShow(matchMedia);
    setShowArrows(!matchMedia);

    window.addEventListener("resize", showListener);
    return () => window.removeEventListener("resize", showListener);
  }, []);

  const showListener = () => {
    const matchMedia = window.matchMedia("(min-width: 640px)")?.matches;

    setShow(matchMedia);
    setShowArrows(!matchMedia);
  };

  return (
    <div className="mb-2">
      <div className="flex justify-between cursor-pointer sm:cursor-auto" onClick={() => setShow(!isShow)}>
        <h1 className="uppercase text-gray-600 text-lg">{menu?.title}</h1>
        {showArrows && <>{!isShow ? <ArrowDropRightIcon className="w-5 h-5 cursor-pointer" /> : <ArrowDropUpIcon className="w-5 h-5 cursor-pointer" />}</>}
      </div>
      {!menu?.links.length ? (
        <Skeleton count={5} width={150} style={{ marginBottom: "15px" }} />
      ) : (
        menu?.links?.map(
          (link, index) =>
            !isParentMenuLink(link) &&
            isShow && (
              <div className="mb-4" key={`footerLinkItem${link.id ?? index}`}>
                <Link href={`${link.href}`} className="text-[16px] md:text-lg text-gray-600 font-medium hover:underline" target={`${link?.target}`}>
                  {link.name}
                </Link>
              </div>
            ),
        )
      )}
    </div>
  );
};
