"use client";

import clsx from "clsx";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { MenuLink } from "@/data/Menu";
import { LeafMenuLink, ParentMenuLink, StrapiMenuLink } from "@/data/Menu/fetch-strapi-menu";
import { useLayout } from "@/lib/contexts/layout-context";

export const CategoryDropdownSkeleton = () => {
  return (
    <div className="overflow-x-auto flex pb-1 sm:pb-2 xl:pb-3" style={{ scrollBehavior: "smooth", scrollbarWidth: "none" }}>
      <div className="pr-3 uppercase">
        {[1, 2, 3, 4].map((index) => (
          <Skeleton key={index} width={65} height={20} containerClassName={"inline-block pr-3"} className={"xl:!w-[100px]"} />
        ))}
      </div>
    </div>
  );
};

export const CategoryDropdown = ({ links, noHidden, categories }: { links: MenuLink[]; noHidden?: boolean; categories?: MenuLink[] }) => {
  const categoryLinks = useRef<HTMLDivElement>(null);
  const { handleSearching } = useLayout();
  let mouseDown = false;
  let startX: number, scrollLeft: number;

  const startDragging = (e: MouseEvent) => {
    if (!categoryLinks.current) {
      return;
    }
    mouseDown = true;
    startX = e.pageX - categoryLinks.current.offsetLeft;
    scrollLeft = categoryLinks.current.scrollLeft;
  };

  const stopDragging = () => {
    mouseDown = false;
  };

  const move = (e: MouseEvent) => {
    e.preventDefault();
    if (!mouseDown || !categoryLinks.current) {
      return;
    }
    const x = e.pageX - categoryLinks.current.offsetLeft;
    const scroll = x - startX;
    categoryLinks.current.scrollLeft = scrollLeft - scroll;
  };

  useEffect(() => {
    const categoryLinksElement = categoryLinks.current;
    categoryLinksElement?.addEventListener("mousemove", move, false);
    categoryLinksElement?.addEventListener("mousedown", startDragging, false);
    categoryLinksElement?.addEventListener("mouseup", stopDragging, false);
    categoryLinksElement?.addEventListener("mouseleave", stopDragging, false);

    return () => {
      categoryLinksElement?.removeEventListener("mousemove", move, false);
      categoryLinksElement?.removeEventListener("mousedown", startDragging, false);
      categoryLinksElement?.removeEventListener("mouseup", stopDragging, false);
      categoryLinksElement?.removeEventListener("mouseleave", stopDragging, false);
    };
  });

  if (!links.length) {
    return null;
  }

  return (
    <div className={`${!noHidden ? "hidden lg:block" : null}`}>
      <div className="overflow-x-auto flex scrolling-touch select-none" ref={categoryLinks} style={{ scrollBehavior: "smooth", scrollbarWidth: "none" }}>
        <ShowCategories categories={categories} onClick={() => handleSearching(false)} noHidden={noHidden} />
        {links.map((link: MenuLink, index) => {
          return "href" in link ? (
            <SingleCategory {...link} small={noHidden} onClick={() => handleSearching(false)} index={index} />
          ) : (
            <DropdownCategory onClick={() => handleSearching(false)} small={noHidden} {...link} index={index} />
          );
        })}
      </div>
    </div>
  );
};

const ShowCategories = ({ categories, onClick, noHidden }: { categories?: MenuLink[]; onClick: () => void; noHidden?: boolean }) => {
  if (!categories?.length) {
    return <></>;
  }

  return <DropdownCategory onClick={onClick} small={noHidden} links={categories} index={1} id={1} name={"Sortiment"} />;
};

const DropdownCategory = ({ ...category }: ParentMenuLink & { index: number; small?: boolean; onClick?: () => void }) => {
  const [isShow, setShow] = useState<boolean>(false);

  return (
    <div className="flex">
      <div onMouseOver={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        <div className="pr-3" key={`categoryLink${category.id ?? category.index}`}>
          <div
            className={clsx("block text-center font-bold hover:underline rounded-xl p-1 whitespace-nowrap pl-2 pr-2 text-kodi-blue cursor-pointer", {
              "lg:text-lg text-md": category.small,
              "text-lg": !category.small,
            })}
          >
            <div>{category.name}</div>
          </div>
        </div>

        <div className={`z-10 ${!isShow ? "hidden" : "block"} bg-gray-50 absolute rounded-md`}>
          <ul className="py-2 text-sm text-gray-700 font-medium max-w-[40rem]" aria-labelledby="dropdownHoverButton">
            {category.links.map((value: StrapiMenuLink, index: number) => {
              const link = value as LeafMenuLink;
              return (
                <li key={index}>
                  <Link href={link?.href} target={link?.target ?? "_self"} className="block px-4 py-2 hover:underline" onClick={category.onClick}>
                    {link.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

const SingleCategory = ({ ...category }: MenuLink & { index: number; small?: boolean; onClick?: () => void }) => {
  if ("href" in category) {
    return (
      <div className="pr-3" key={`categoryLink${category.id ?? category.index}`}>
        <Link
          href={category.href ?? ""}
          target={category?.target ?? "_self"}
          onClick={category.onClick}
          className={clsx("block text-center font-bold hover:underline rounded-xl p-1 whitespace-nowrap pl-2 pr-2 text-kodi-blue", {
            "lg:text-lg text-md": category.small,
            "text-lg": !category.small,
          })}
        >
          {category.name}
        </Link>
      </div>
    );
  }

  return null;
};
