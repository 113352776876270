"use client";

import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { SortButton } from "@/components/Button/SortButton";
import { Products } from "@/components/Search/components/Products";
import { useLayout } from "@/lib/contexts/layout-context";

interface SearchOverlayInterface {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}

export const SearchComponent = ({ searchValue, setSearchValue }: SearchOverlayInterface) => {
  const [sort, setSort] = useState<string>("");
  const { isSearching, handleSearching } = useLayout();

  useEffect(() => {
    if (searchValue.length) {
      handleSearching(true);
    }

    const body = document.getElementsByTagName("body")[0];

    if (!body) {
      return;
    }

    body.style.overflowY = !searchValue.length ? "auto" : "hidden";
  }, [searchValue]);

  if (!searchValue.length) {
    return <></>;
  }

  return (
    <div
      className={clsx("relative w-full h-[85vh] py-10 z-0", {
        hidden: !isSearching,
      })}
    >
      <div className="absolute bg-white h-full overflow-y-scroll inset-0 mt-10">
        <p className="text-xl md:text-2xl lg:text-4xl font-medium text-kodi-blue mb-10">Suchergebnisse Produktsuche</p>

        <div className="my-3">
          <SortButton setSort={setSort} />
        </div>

        <Products searchValue={searchValue} onClick={() => setSearchValue("")} sort={sort} />
      </div>
    </div>
  );
};
