"use client";

import clsx from "clsx";
import React, { useCallback, useContext, useEffect } from "react";
import { createPortal } from "react-dom";

import { CloseLineIcon } from "@/components/Icon/Close/CloseLineIcon";
import { FlyoutContext } from "@/lib/contexts/flyout-context";

interface FlyoutProps extends React.PropsWithChildren {
  open: boolean;
  onClose?: () => void;
  className?: string;
  right?: boolean;
  button?: React.ReactNode;
  title?: string;
}

export const FlyoutPortal = () => {
  const { setPortal } = useContext(FlyoutContext);

  return <div ref={(node) => setPortal(node)} data-id="flyout-portal" />;
};

const calculateHeaderHeight = () => {
  const header = document.querySelector("header");
  let height = header?.clientHeight || 0;

  if (header?.classList.contains("sticky")) {
    const stickyTop = parseInt(header.style.top);
    if (stickyTop < 0) {
      height += Math.max(stickyTop, window.scrollY * -1);
    }
  }

  return height;
};

export const Flyout = ({ children, open, className, right, button, onClose, title }: FlyoutProps) => {
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const { portal } = useContext(FlyoutContext);
  const onCloseClick = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  useEffect(() => {
    if (open) {
      setHeaderHeight(calculateHeaderHeight());
    }
  }, [open]);

  const listener = () => {
    if (open) {
      portal?.focus({ preventScroll: false });
    }

    setHeaderHeight(calculateHeaderHeight());
  };

  const headerHeightEvents = () => {
    window.addEventListener("resize", listener, false);
    window.addEventListener("scroll", listener, false);
  };

  const clearHeaderHeightEvents = () => {
    window.removeEventListener("resize", listener, false);
    window.removeEventListener("scroll", listener, false);
  };

  useEffect(() => {
    headerHeightEvents();
    return () => clearHeaderHeightEvents();
  });

  if (!portal) {
    return null;
  }

  return createPortal(
    <div className={clsx("fixed z-30 inset-0 flex flex-col", { "items-end": right }, !open ? "hidden" : "block")} style={{ paddingTop: headerHeight }}>
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-900 opacity-75" onClick={onCloseClick} />
      </div>
      <div
        className={clsx(`w-full sm:w-4/6 md:w-3/6 lg:w-2/6 bg-white transform transition-all flex flex-row flex-1`, {
          "flex-row-reverse": right,
        })}
      >
        <div className="flex w-full flex-1 flex-col overflow-hidden p-4">
          <div className="flex items-center mb-5">
            <p className="text-xl flex-1 uppercase text-center">{title}</p>
            <div className="flex-2 text-end" onClick={onCloseClick}>
              <div className={`relative mx-2 cursor-pointer z-10`} onClick={onCloseClick}>
                <CloseLineIcon className="fill-black" width={40} onClick={onCloseClick} />
              </div>
            </div>
          </div>

          <div
            className={"bg-white text-left overflow-x-hidden overflow-y-auto flex-auto basis-0"}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className={clsx("bg-white", className)}>{children}</div>
          </div>
          {button && (
            <div className={"w-full p-4 bg-white flex-0"} style={{ boxShadow: " 0 -10px 20px -5px rgba(115,115,115,0.25)" }}>
              {button}
            </div>
          )}
        </div>
      </div>
    </div>,
    portal,
  );
};
