"use client";

import React from "react";

type ContextType = {
  portal: HTMLDivElement | null;
  setPortal(node: HTMLDivElement | null): void;
};
export const FlyoutContext = React.createContext<ContextType>(undefined as unknown as ContextType);

export function FlyoutProvider({ children }: React.PropsWithChildren) {
  const [portal, setPortal] = React.useState<HTMLDivElement | null>(null);

  return (
    <FlyoutContext.Provider
      value={{
        portal,
        setPortal,
      }}
    >
      {children}
    </FlyoutContext.Provider>
  );
}
