"use client";

import { useEffect, useState } from "react";

const getHash = () => (typeof window !== "undefined" ? decodeURIComponent(window.location.hash.replace("#", "")) : undefined);

const useHash = () => {
  const currentHash = getHash();
  const [hash, setHash] = useState(currentHash);
  if (currentHash !== hash) {
    setHash(currentHash);
  }

  useEffect(() => {
    const handleHashChange = () => {
      setHash(getHash());
    };
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return hash;
};

export default useHash;
