"use client";

import clsx from "clsx";
import Link from "next/link";
import React from "react";
import Skeleton from "react-loading-skeleton";

import { FooterItemCollapse } from "@/components/Footer/components/FooterItemCollapse";
import { isParentMenuLink, RootMenu } from "@/data/Menu/fetch-strapi-menu";

type headLink = {
  title: string;
  url: string;
};

export const FooterItemMenu = ({ menu, headLink }: { menu: RootMenu; headLink?: headLink }) => {
  return (
    <div>
      {headLink && (
        <div className="mb-2 text-gray-600 font-bold text-sm">
          <p>{headLink.title}</p>
        </div>
      )}
      {!menu?.links.length ? (
        <Skeleton count={5} width={150} style={{ marginBottom: "15px" }} />
      ) : (
        menu?.links?.map((link, index) => {
          if (isParentMenuLink(link)) {
            return <FooterItemCollapse menu={link as unknown as RootMenu} key={`footerCollapse${link.id ?? index}`} />;
          }

          return (
            <div className="mb-1" key={`footerLinkItem${link.id ?? index}`}>
              <Link
                href={`${link.href}`}
                className={clsx("text-[16px] md:text-sm text-gray-600 hover:underline", { "text-bold": isParentMenuLink(link) })}
                target={`${link?.target}`}
              >
                {link.name}
              </Link>
            </div>
          );
        })
      )}
    </div>
  );
};
