import fetchAPI from "../../utils/fetch-strapi";

interface StrapiItemAttributesInterface {
  attributes: {
    title: string;
    url?: string;
    children?: StrapiItemAttributesChildInterface;
  };
}

interface StrapiItemAttributesChildInterface extends StrapiItemAttributesInterface {
  data?: StrapiItemAttributesInterface[];
}

interface FilterItemInterface {
  id: number;
  attributes: {
    title: string;
    url?: string;
    target?: string;
    children: {
      data: unknown[];
    };
  };
}

const filterMenu = (item: FilterItemInterface): StrapiMenuLink => {
  if (item?.attributes?.children?.data?.length >= 1) {
    return {
      name: item.attributes.title,
      links: item.attributes.children.data.map((item) => filterMenu(item as FilterItemInterface)),
      id: item.id,
    };
  }

  return {
    name: item.attributes.title,
    href: item?.attributes?.url ?? "",
    target: item?.attributes?.target,
    id: item?.id.toString(),
  };
};

export type RootMenu = {
  title: string;
  links: StrapiMenuLinks;
};
export type ParentMenuLink = {
  name: string;
  links: StrapiMenuLinks;
  id: number;
};
export type LeafMenuLink = {
  name: string;
  href: string;
  id: string;
  target?: string;
  underline?: boolean;
};
export type StrapiMenuLink = ParentMenuLink | LeafMenuLink;

export const isParentMenuLink = (link: StrapiMenuLink): link is ParentMenuLink => {
  return (link as ParentMenuLink).links !== undefined;
};

export type StrapiMenuLinks = StrapiMenuLink[];

export async function loadMenu(slug: string): Promise<RootMenu | undefined> {
  return fetchAPI(
    "/menus",
    {
      nested: true,
      populate: "*",
      filters: { slug: { $eq: slug } },
    },
    {
      timeout: 5000,
    },
  )
    .then((response) => {
      if (response.data && response.data.length >= 1) {
        const menu = response.data.at(0).attributes;

        return {
          title: menu.title,
          links: menu.items.data.map(filterMenu),
          id: response.data.at(0).id,
        };
      }
      return undefined;
    })
    .catch((error) => {
      console.error(`Failed to load menu ${slug} from Strapi`, error);
      return { title: "", links: [] };
    });
}
