import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";

interface SearchInputComponentProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  placeholder?: string;
  onReset?: () => void;
  value: string;
}

export const SearchInputComponent = ({ placeholder, value, onReset, ...rest }: SearchInputComponentProps) => (
  <div className="relative flex items-center justify-between w-full">
    <input
      {...rest}
      autoFocus
      className={clsx(
        "text-md md:text-sm pl-4 py-1.5 tracking-tighter transition-colors duration-300 bg-gray-100 border-transparent rounded-full w-full p-[0.15rem] focus:border focus:border-gray-400 focus:outline-none placeholder-kodi-darkgray",
      )}
      type="search"
      value={value}
      placeholder={placeholder !== undefined ? placeholder : "Suchen"}
    />
    <span className="absolute end-1 pl-2">
      <MagnifyingGlassIcon className="w-5 text-gray-500 ml-auto mr-2" onClick={onReset} />
    </span>
  </div>
);

const SearchInput = ({ placeholder, value, onReset, ...rest }: SearchInputComponentProps) => (
  <>
    <div className="hidden md:block mx-auto w-full">
      <SearchInputComponent value={value} onReset={onReset} {...rest} />
    </div>
    <div className="md:hidden ml-1 sm:ml-2 lg:w-9/12 w-full mx-auto pr-0 sm:pr-5">
      <SearchInputComponent placeholder={placeholder} value={value} onReset={onReset} {...rest} />
    </div>
  </>
);

export default SearchInput;
